"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var preact_1 = require("preact");
var history_1 = require("history");
var preact_router_1 = require("preact-router");
var Editor_1 = require("./Editor");
var NotFound_1 = require("./NotFound");
var Redirect_1 = require("./Redirect");
var Search_1 = require("./Search");
exports.default = ((0, preact_1.h)(preact_router_1.Router, { history: (0, history_1.createHashHistory)() },
    (0, preact_1.h)(Redirect_1.Redirect, { path: "/", to: "/edit" }),
    (0, preact_1.h)(Editor_1.Editor, { path: "/edit", slug: "" }),
    (0, preact_1.h)(Editor_1.Editor, { path: "/edit/:slug" }),
    (0, preact_1.h)(Search_1.Search, { path: "/search" }),
    (0, preact_1.h)(NotFound_1.NotFound, { default: true })));
