"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditorTopBar = void 0;
var preact_1 = require("preact");
var decko_1 = require("decko");
var EditorTopBar = (function (_super) {
    __extends(EditorTopBar, _super);
    function EditorTopBar() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    EditorTopBar.prototype.render = function (props) {
        return ((0, preact_1.h)("nav", { id: "editor-topbar" },
            (0, preact_1.h)("div", { className: "brand" },
                (0, preact_1.h)("img", { src: "/favicon-32x32.png", className: "logo", alt: "JS Logo" }),
                (0, preact_1.h)("span", null, "Pixi Playground")),
            (0, preact_1.h)("div", { className: "title" },
                (0, preact_1.h)("span", null, props.name)),
            (0, preact_1.h)("div", { className: "btn-group" },
                (0, preact_1.h)("button", { className: "btn", onClick: this._onUpdateClick },
                    (0, preact_1.h)("span", { className: "fa fa-sync", "aria-hidden": "true" }),
                    (0, preact_1.h)("span", { className: "label" }, "Refresh")),
                (0, preact_1.h)("button", { className: "btn", onClick: this._onSettingsClick },
                    (0, preact_1.h)("span", { className: "fa fa-cogs", "aria-hidden": "true" }),
                    (0, preact_1.h)("span", { className: "label" }, "Settings")),
                props.showClone ? ((0, preact_1.h)("button", { className: "btn", onClick: this._onCloneClick },
                    (0, preact_1.h)("span", { className: "far fa-clone", "aria-hidden": "true" }),
                    (0, preact_1.h)("span", { className: "label" }, "Clone"))) : '',
                (0, preact_1.h)("button", { className: "btn" + (props.dirty ? " glow" : ""), onClick: this._onSaveClick },
                    (0, preact_1.h)("span", { className: "fa fa-save", "aria-hidden": "true" }),
                    (0, preact_1.h)("span", { className: "label" }, "Save"),
                    (0, preact_1.h)("span", { className: props.saving ? "loading" : " hidden" })))));
    };
    EditorTopBar.prototype._onUpdateClick = function () {
        if (this.props.onUpdateClick)
            this.props.onUpdateClick();
    };
    EditorTopBar.prototype._onSettingsClick = function () {
        if (this.props.onSettingsClick)
            this.props.onSettingsClick();
    };
    EditorTopBar.prototype._onCloneClick = function () {
        if (this.props.onCloneClick)
            this.props.onCloneClick();
    };
    EditorTopBar.prototype._onSaveClick = function () {
        if (this.props.onSaveClick)
            this.props.onSaveClick();
    };
    __decorate([
        decko_1.bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], EditorTopBar.prototype, "_onUpdateClick", null);
    __decorate([
        decko_1.bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], EditorTopBar.prototype, "_onSettingsClick", null);
    __decorate([
        decko_1.bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], EditorTopBar.prototype, "_onCloneClick", null);
    __decorate([
        decko_1.bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], EditorTopBar.prototype, "_onSaveClick", null);
    return EditorTopBar;
}(preact_1.Component));
exports.EditorTopBar = EditorTopBar;
