"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Editor = void 0;
var monaco = require("monaco-editor/esm/vs/editor/editor.api");
var preact_1 = require("preact");
var preact_router_1 = require("preact-router");
var decko_1 = require("decko");
var service_1 = require("../service");
var MonacoEditor_1 = require("../components/MonacoEditor");
var EditorTopBar_1 = require("../components/EditorTopBar");
var EditorSettingsDialog_1 = require("../components/EditorSettingsDialog");
var alertShowTime = 4000;
var activePixiTypings = null;
var LoadingFlag;
(function (LoadingFlag) {
    LoadingFlag[LoadingFlag["Playground"] = 1] = "Playground";
    LoadingFlag[LoadingFlag["Editor"] = 2] = "Editor";
    LoadingFlag[LoadingFlag["Typings"] = 4] = "Typings";
    LoadingFlag[LoadingFlag["All"] = 7] = "All";
})(LoadingFlag || (LoadingFlag = {}));
var Editor = (function (_super) {
    __extends(Editor, _super);
    function Editor(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this._splitter = null;
        _this._editorInstance = null;
        _this._resultIFrame = null;
        _this._onChangeDelay = 1000;
        _this._onChangeTimer = 0;
        var isMobile = !!window.matchMedia("only screen and (max-width: 540px)").matches;
        _this._loadingFlags = LoadingFlag.All;
        if (!_this.props.slug)
            _this._loadingFlags &= ~LoadingFlag.Playground;
        _this.state = {
            loadingFlags: _this._loadingFlags,
            saving: false,
            showSettings: false,
            dirty: true,
            oldPixiVersion: 'release',
            data: {
                pixiVersion: 'release',
                isPublic: true,
                autoUpdate: true,
            },
            alert: {
                type: 'info',
                msg: '',
                timeout: 0,
                show: false,
            },
            editorState: {
                splitAmount: 50
            },
            splitterIsDragged: false,
            isMobile: isMobile,
            isEditor: false
        };
        _this.loadEditorConfig();
        _this.loadPlayground();
        return _this;
    }
    Editor.prototype.setLoading = function (flag, loading) {
        if (loading)
            this._loadingFlags |= flag;
        else
            this._loadingFlags &= ~flag;
        this.setState({ loadingFlags: this._loadingFlags });
    };
    Editor.prototype.isLoading = function (flag) {
        return (this._loadingFlags & flag) === flag;
    };
    Editor.prototype.componentDidUpdate = function (props, state) {
        var _this = this;
        clearTimeout(this._onSaveTimer);
        this._onSaveTimer = window.setTimeout(function () { return _this.saveEditorConfig(); }, 300);
    };
    Editor.prototype.componentWillMount = function () {
        window.addEventListener('keydown', this._onKeydown);
        window.onbeforeunload = this._onBeforeUnload;
    };
    Editor.prototype.componentWillUnmount = function () {
        window.removeEventListener('keydown', this._onKeydown);
        if (this._splitter)
            this._splitter.removeEventListener('pointerdown', this._onSplitterDown);
        window.onbeforeunload = null;
    };
    Editor.prototype.loadPlayground = function () {
        var _this = this;
        if (!this.props.slug) {
            this.onEditorValueChange(this._editorInstance ? this._editorInstance.getValue() : '');
        }
        else {
            (0, service_1.getPlayground)(this.props.slug, function (err, data) {
                if (err) {
                    _this.setLoading(LoadingFlag.Playground, false);
                    (0, preact_router_1.route)("/edit");
                    _this._showAlert('error', err.message);
                }
                else {
                    _this.setLoading(LoadingFlag.Playground, false);
                    _this.setState({ data: data });
                }
                if (_this._dialogInstance)
                    _this._dialogInstance.updatePlaygroundData(data);
                _this.onEditorValueChange(data.contents || (_this._editorInstance ? _this._editorInstance.getValue() : ''));
            });
        }
    };
    Editor.prototype.loadTypings = function () {
        var _this = this;
        var version = this.state.data.pixiVersion;
        this.setLoading(LoadingFlag.Typings, true);
        (0, service_1.getTypings)(version, function (typings) {
            if (typings)
                _this.enableTypings(typings);
            _this.setLoading(LoadingFlag.Typings, false);
            _this.onEditorValueChange(_this._editorInstance.getValue());
        });
    };
    Editor.prototype.enableTypings = function (typings) {
        if (activePixiTypings)
            activePixiTypings.dispose();
        var jsDefaults = monaco.languages.typescript.javascriptDefaults;
        activePixiTypings = jsDefaults.addExtraLib(typings, 'pixi.js.d.ts');
    };
    Editor.prototype.loadEditorConfig = function () {
        var data = JSON.parse(localStorage.getItem("editorState"));
        if (!data)
            return;
        this.state.editorState.splitAmount = data.splitAmount || 50;
    };
    Editor.prototype.saveEditorConfig = function () {
        var data = this.state.editorState;
        localStorage.setItem("editorState", JSON.stringify(data));
    };
    Editor.prototype.updateDemo = function () {
        if (this._isLoadingAny() || !this._resultIFrame || !this._resultIFrame.contentWindow)
            return;
        this._resultIFrame.contentWindow.location.reload();
    };
    Editor.prototype.onSplitterMounded = function (splitter) {
        if (!this._splitter)
            this._splitter = splitter;
        this._splitter.addEventListener("pointerdown", this._onSplitterDown);
    };
    Editor.prototype._onSplitterDown = function (event) {
        this.setState({
            splitterIsDragged: true
        });
        this._splitterOverlay.addEventListener("pointermove", this._onSplitterMove);
        this._splitterOverlay.addEventListener("pointercancel", this._onSplitterReleased);
        this._splitterOverlay.addEventListener("pointerout", this._onSplitterReleased);
        this._splitterOverlay.addEventListener("pointerup", this._onSplitterReleased);
    };
    Editor.prototype._onSplitterReleased = function (event) {
        this.setState({
            splitterIsDragged: false
        });
        this._splitterOverlay.removeEventListener("pointermove", this._onSplitterMove);
        this._splitterOverlay.removeEventListener("pointercancel", this._onSplitterReleased);
        this._splitterOverlay.removeEventListener("pointerout", this._onSplitterReleased);
        this._splitterOverlay.removeEventListener("pointerup", this._onSplitterReleased);
    };
    Editor.prototype._onSplitterMove = function (event) {
        var width = this._splitterOverlay.clientWidth;
        var x = event.clientX;
        this.setState({ editorState: { splitAmount: 100 * x / width } });
    };
    Editor.prototype.onSettingsMount = function (dialog) {
        this._dialogInstance = dialog;
        dialog.updatePlaygroundData(this.state.data);
    };
    Editor.prototype.onEditorMount = function (editor, model) {
        this._editorInstance = editor;
        this.loadTypings();
        this.setLoading(LoadingFlag.Editor, false);
        this.onEditorValueChange(editor.getValue());
    };
    Editor.prototype.onResultIFrameMount = function (iframe) {
        if (this._resultIFrame)
            this._resultIFrame.removeEventListener('load', this._onResultIFrameLoaded, false);
        this._resultIFrame = iframe;
        iframe.addEventListener('load', this._onResultIFrameLoaded, false);
    };
    Editor.prototype.onEditorValueChange = function (newValue) {
        var _this = this;
        if (this._isLoadingAny())
            return;
        this.state.data.contents = newValue;
        this.setState({ dirty: true });
        clearTimeout(this._onChangeTimer);
        if (this.state.data.autoUpdate) {
            this._onChangeTimer = window.setTimeout(function () {
                _this.updateDemo();
            }, this._onChangeDelay);
        }
    };
    Editor.prototype.render = function (props, state) {
        var _this = this;
        return ((0, preact_1.h)("div", { id: "editor-full-wrapper" },
            (0, preact_1.h)("div", { id: "alert" },
                (0, preact_1.h)("span", { className: state.alert.type + (state.alert.show ? ' shown' : '') }, state.alert.msg)),
            (0, preact_1.h)("div", { id: "editor-loading-info", className: "fullscreen", style: { display: this._isLoadingAny() ? '' : 'none' } },
                (0, preact_1.h)("ul", null,
                    this._renderLoadingInfoItem(this.isLoading(LoadingFlag.Playground), 'Playground data'),
                    this._renderLoadingInfoItem(this.isLoading(LoadingFlag.Editor), 'Monaco editor'),
                    this._renderLoadingInfoItem(this.isLoading(LoadingFlag.Typings), 'PixiJS types'))),
            (0, preact_1.h)(EditorSettingsDialog_1.EditorSettingsDialog, { ref: this.onSettingsMount, data: state.data, visible: state.showSettings, onSaveClick: this._saveSettings, onCloseClick: this._hideSettings }),
            (0, preact_1.h)(EditorTopBar_1.EditorTopBar, { name: state.data.name, saving: state.saving, dirty: state.dirty, showClone: !!this.state.data.id, onUpdateClick: this.updateDemo, onSettingsClick: this._showSettings, onCloneClick: this._clone, onSaveClick: this._save }),
            (0, preact_1.h)("div", { className: "wrap-container" },
                (0, preact_1.h)("div", { id: "editor-wrapper", style: "width:" + (state.editorState.splitAmount) + "%", className: "wrap " + (state.isEditor ? "full" : "hide") },
                    (0, preact_1.h)(MonacoEditor_1.MonacoEditor, { value: state.data && state.data.contents ? state.data.contents : getDefaultPlayground(), theme: 'vs-dark', options: {
                            automaticLayout: true,
                            fontSize: state.isMobile ? 10 : undefined,
                            codeLens: !state.isMobile,
                            readOnly: state.isMobile,
                            minimap: {
                                enabled: !state.isMobile
                            }
                        }, onChange: this.onEditorValueChange, editorDidMount: this.onEditorMount })),
                (0, preact_1.h)("div", { id: "results-wrapper", style: "width:" + (100 - state.editorState.splitAmount) + "%", className: "wrap " + (!state.isEditor ? "full" : "hide") },
                    (0, preact_1.h)("iframe", { id: "results-frame", src: "results.html", ref: this.onResultIFrameMount, title: "Playground Results" })),
                (0, preact_1.h)("div", { ref: this.onSplitterMounded, style: "left:" + state.editorState.splitAmount + "%", className: "wrap-splitter " + (state.splitterIsDragged ? "active" : "") }),
                (0, preact_1.h)("div", { ref: function (ov) { return _this._splitterOverlay = ov; }, className: "wrap-overlay " + (state.splitterIsDragged ? "active" : "") })),
            (0, preact_1.h)("div", { class: "toggle-area" },
                (0, preact_1.h)("label", { class: "switch" },
                    (0, preact_1.h)("input", { type: "checkbox", checked: state.isEditor, onChange: this._switchEditorMode }),
                    (0, preact_1.h)("span", { class: "slider round" })),
                (0, preact_1.h)("span", null,
                    " ",
                    state.isEditor ? 'Editor' : 'Preview',
                    " "))));
    };
    Editor.prototype._renderLoadingInfoItem = function (isLoading, name) {
        return ((0, preact_1.h)("li", { className: isLoading ? 'loading' : 'done' },
            (0, preact_1.h)("span", { className: "fa fa-check" }),
            isLoading ? 'Loading ' : '',
            name,
            isLoading ? '...' : ' ready!'));
    };
    Editor.prototype._isLoadingAny = function () {
        return (this._loadingFlags & LoadingFlag.All) !== 0;
    };
    Editor.prototype._showAlert = function (type, msg) {
        var _this = this;
        if (this.state.alert)
            clearTimeout(this.state.alert.timeout);
        var timeout = window.setTimeout(function () {
            var a = _this.state.alert;
            _this.setState({ alert: { type: a.type, msg: a.msg, timeout: a.timeout, show: false } });
        }, alertShowTime);
        this.setState({ alert: { type: type, msg: msg, timeout: timeout, show: true } });
    };
    Editor.prototype._switchEditorMode = function (event) {
        var checked = event.target.checked;
        this.setState({
            isEditor: checked
        });
    };
    Editor.prototype._showSettings = function () {
        this.setState({ showSettings: true, oldPixiVersion: this.state.data.pixiVersion });
    };
    Editor.prototype._hideSettings = function () {
        this.setState({ showSettings: false, dirty: true });
        if (this.state.data.pixiVersion != this.state.oldPixiVersion) {
            this.loadTypings();
        }
    };
    Editor.prototype._saveSettings = function (data) {
        this.setState({ data: data, showSettings: false });
        this._save();
        if (this.state.data.pixiVersion != this.state.oldPixiVersion) {
            this.loadTypings();
        }
        else {
            this.updateDemo();
        }
    };
    Editor.prototype._onResultIFrameLoaded = function () {
        this._resultIFrame.contentWindow.postMessage(this.state.data, location.origin);
    };
    Editor.prototype._onKeydown = function (event) {
        if (event.ctrlKey || event.metaKey) {
            if (String.fromCharCode(event.which).toLowerCase() == 's') {
                event.preventDefault();
                this._save();
            }
        }
    };
    Editor.prototype._onBeforeUnload = function (event) {
        if (this.state.dirty) {
            event.preventDefault();
            return '';
        }
    };
    Editor.prototype._clone = function () {
        var _this = this;
        this.setState({ saving: true });
        (0, service_1.createPlayground)(this.state.data, function (err, data) {
            if (!err && data) {
                _this.setState({ data: data, saving: false, dirty: false });
                (0, preact_router_1.route)("/edit/".concat(data.slug));
                _this._showAlert('success', 'Playground Cloned!');
            }
            else {
                _this.setState({ saving: false });
                _this._showAlert('error', err.message);
            }
        });
    };
    Editor.prototype._save = function () {
        var _this = this;
        this.setState({ saving: true, dirty: false });
        if (this.state.data.id) {
            (0, service_1.updatePlayground)(this.state.data, function (err, data) {
                if (!err && data) {
                    _this.setState({ data: data, saving: false });
                    _this._showAlert('success', 'Playground Saved!');
                }
                else {
                    _this.setState({ saving: false });
                    _this._showAlert('error', err.message);
                }
            });
        }
        else {
            (0, service_1.createPlayground)(this.state.data, function (err, data) {
                if (!err && data) {
                    _this.setState({ data: data, saving: false });
                    (0, preact_router_1.route)("/edit/".concat(data.slug));
                    _this._showAlert('success', 'Playground Created!');
                }
                else {
                    _this.setState({ saving: false });
                    _this._showAlert('error', err.message);
                }
            });
        }
    };
    __decorate([
        decko_1.bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], Editor.prototype, "updateDemo", null);
    __decorate([
        decko_1.bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Element]),
        __metadata("design:returntype", void 0)
    ], Editor.prototype, "onSplitterMounded", null);
    __decorate([
        decko_1.bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [PointerEvent]),
        __metadata("design:returntype", void 0)
    ], Editor.prototype, "_onSplitterDown", null);
    __decorate([
        decko_1.bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [PointerEvent]),
        __metadata("design:returntype", void 0)
    ], Editor.prototype, "_onSplitterReleased", null);
    __decorate([
        decko_1.bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [PointerEvent]),
        __metadata("design:returntype", void 0)
    ], Editor.prototype, "_onSplitterMove", null);
    __decorate([
        decko_1.bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [EditorSettingsDialog_1.EditorSettingsDialog]),
        __metadata("design:returntype", void 0)
    ], Editor.prototype, "onSettingsMount", null);
    __decorate([
        decko_1.bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], Editor.prototype, "onEditorMount", null);
    __decorate([
        decko_1.bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [HTMLIFrameElement]),
        __metadata("design:returntype", void 0)
    ], Editor.prototype, "onResultIFrameMount", null);
    __decorate([
        decko_1.bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], Editor.prototype, "onEditorValueChange", null);
    __decorate([
        decko_1.bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], Editor.prototype, "_switchEditorMode", null);
    __decorate([
        decko_1.bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], Editor.prototype, "_showSettings", null);
    __decorate([
        decko_1.bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], Editor.prototype, "_hideSettings", null);
    __decorate([
        decko_1.bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], Editor.prototype, "_saveSettings", null);
    __decorate([
        decko_1.bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], Editor.prototype, "_onResultIFrameLoaded", null);
    __decorate([
        decko_1.bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [KeyboardEvent]),
        __metadata("design:returntype", void 0)
    ], Editor.prototype, "_onKeydown", null);
    __decorate([
        decko_1.bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Event]),
        __metadata("design:returntype", void 0)
    ], Editor.prototype, "_onBeforeUnload", null);
    __decorate([
        decko_1.bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], Editor.prototype, "_clone", null);
    __decorate([
        decko_1.bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], Editor.prototype, "_save", null);
    return Editor;
}(preact_1.Component));
exports.Editor = Editor;
function getDefaultPlayground() {
    return "/**\n* This is the default playground.\n* You should see a bunny spinning in the right preview pane.\n* Feel free to use this as a starting point for you own playground!\n*/\n\n// Create our application instance\n(async () => {\n    const app = new PIXI.Application();\n    await app.init({\n        width: window.innerWidth,\n        height: window.innerHeight,\n        backgroundColor: 0x2c3e50\n    })\n    document.body.appendChild(app.canvas);\n\n    // Load the bunny texture\n    const texture = await PIXI.Assets.load('https://pixijs.io/examples/examples/assets/bunny.png')\n\n    // Create a new Sprite using the texture\n    const bunny = new PIXI.Sprite(texture);\n\n    // Center the sprite's anchor point\n    bunny.anchor.set(0.5);\n\n    // Move the sprite to the center of the screen\n    bunny.x = app.renderer.width / 2;\n    bunny.y = app.renderer.height / 2;\n\n    app.stage.addChild(bunny);\n\n    // Listen for animate update\n    app.ticker.add(function(ticker)\n    {\n        // Rotate mr rabbit clockwise\n        bunny.rotation += 0.1 * ticker.deltaTime;\n    });\n})();\n";
}
