"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getQueryParam = void 0;
function getQueryParam(name, search) {
    if (search === void 0) { search = window.location.href; }
    if (!search)
        return null;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp("[?&]".concat(name, "(=([^&#]*)|&|#|$)"));
    var results = regex.exec(search);
    if (!results)
        return null;
    if (!results[2])
        return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
exports.getQueryParam = getQueryParam;
