"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTypings = exports.getReleases = exports.updatePlayground = exports.createPlayground = exports.getPlayground = exports.searchPlaygrounds = void 0;
var http_1 = require("./http");
var Storage_1 = require("../util/Storage");
var pixiVersionType_1 = require("../util/pixiVersionType");
var baseOrigin = __BASE_ORIGIN__;
if (typeof localStorage !== 'undefined') {
    var apiOriginOverride = localStorage.getItem('apiOriginOverride');
    if (apiOriginOverride) {
        baseOrigin = apiOriginOverride;
    }
}
function searchPlaygrounds(searchStr, cb) {
    (0, http_1.get)("".concat(baseOrigin, "/api/playgrounds?q=").concat(encodeURIComponent(searchStr)), cb);
}
exports.searchPlaygrounds = searchPlaygrounds;
function getPlayground(slug, cb) {
    (0, http_1.get)("".concat(baseOrigin, "/api/playground/").concat(slug), cb);
}
exports.getPlayground = getPlayground;
function createPlayground(data, cb) {
    (0, http_1.post)("".concat(baseOrigin, "/api/playground"), data, cb);
}
exports.createPlayground = createPlayground;
function updatePlayground(data, cb) {
    (0, http_1.put)("".concat(baseOrigin, "/api/playground/").concat(data.slug), data, cb);
}
exports.updatePlayground = updatePlayground;
function getReleases(cb) {
    (0, http_1.get)("https://api.github.com/repos/pixijs/pixi.js/tags?per_page=100", function (err, res) {
        if (err)
            return cb(err);
        if (!res || !Array.isArray(res))
            return cb(new Error('Invalid response from server.'));
        var tags = [];
        var preReleases = {};
        for (var i = 0; i < res.length; ++i) {
            if (res[i].name.includes('-')) {
                var version = res[i].name.split('-')[0];
                if (preReleases[version] && preReleases[version] > 5) {
                    continue;
                }
                preReleases[version] = (preReleases[version] || 0) + 1;
            }
            tags.push(res[i].name);
        }
        cb(null, tags);
    });
}
exports.getReleases = getReleases;
function getTypings(version, cb) {
    var url = '';
    if (version.indexOf('v4') === 0)
        url = "/definitions/".concat(version, "/pixi.d.ts");
    else if (version.indexOf('v5.0.') === 0 && parseInt(version.split('.')[2], 10) < 5)
        url = "/definitions/".concat(version, "/pixi.js.d.ts");
    else if (version === 'release')
        url = "/definitions/release/pixi.js.d.ts";
    else
        url = "https://pixijs.download/".concat(version, "/types/pixi.js.d.ts");
    var cacheable = (0, pixiVersionType_1.getPixiVersionType)(version) === pixiVersionType_1.PixiVersionType.Tag;
    if (cacheable) {
        var cachedTypings_1 = Storage_1.Storage.get(url);
        if (cachedTypings_1) {
            setTimeout(function () { return cb(cachedTypings_1); }, 1);
            return;
        }
    }
    (0, http_1.get)(url, function (err, str) {
        if (!err) {
            if (cacheable)
                Storage_1.Storage.set(url, str);
            cb(str);
        }
        else {
            cb(null);
        }
    });
}
exports.getTypings = getTypings;
