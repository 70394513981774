"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RadioGroup = exports.Radio = void 0;
var preact_1 = require("preact");
var Radio = (function (_super) {
    __extends(Radio, _super);
    function Radio() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Radio.prototype.render = function (props) {
        var _a = this.context.radioGroup, name = _a.name, selectedValue = _a.selectedValue, onChange = _a.onChange;
        var optional = {};
        if (selectedValue !== undefined) {
            optional.checked = (props.value === selectedValue);
        }
        if (typeof onChange === 'function') {
            optional.onChange = onChange.bind(null, props.value);
        }
        if (props.className) {
            props.className += " radio-btn";
        }
        else {
            props.className = "radio-btn";
        }
        return (0, preact_1.h)("input", __assign({}, props, { role: "radio", "aria-checked": optional.checked, type: "radio", name: name }, optional));
    };
    return Radio;
}(preact_1.Component));
exports.Radio = Radio;
var RadioGroup = (function (_super) {
    __extends(RadioGroup, _super);
    function RadioGroup() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RadioGroup.prototype.getChildContext = function () {
        var _a = this.props, name = _a.name, selectedValue = _a.selectedValue, onChange = _a.onChange;
        return {
            radioGroup: {
                name: name,
                selectedValue: selectedValue,
                onChange: onChange,
            },
        };
    };
    RadioGroup.prototype.render = function () {
        var _a = this.props, Component = _a.Component, name = _a.name, selectedValue = _a.selectedValue, onChange = _a.onChange, children = _a.children, rest = __rest(_a, ["Component", "name", "selectedValue", "onChange", "children"]);
        return (0, preact_1.h)(Component, __assign({ className: "radio-group", role: "radiogroup" }, rest), children);
    };
    RadioGroup.defaultProps = {
        Component: 'div',
    };
    return RadioGroup;
}(preact_1.Component));
exports.RadioGroup = RadioGroup;
