"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditorSettingsDialog = void 0;
var linkstate_1 = require("linkstate");
var preact_1 = require("preact");
var decko_1 = require("decko");
var Radio_1 = require("./Radio");
var service_1 = require("../service");
var assertNever_1 = require("../util/assertNever");
var pixiVersionType_1 = require("../util/pixiVersionType");
var EditorSettingsDialog = (function (_super) {
    __extends(EditorSettingsDialog, _super);
    function EditorSettingsDialog(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.state = {
            data: props.data,
            versionType: pixiVersionType_1.PixiVersionType.Release,
            versionOptions: [],
        };
        (0, service_1.getReleases)(function (err, releases) {
            if (!err) {
                _this.setState({ versionOptions: releases });
            }
        });
        return _this;
    }
    EditorSettingsDialog.prototype.updatePlaygroundData = function (data) {
        var versionType = (0, pixiVersionType_1.getPixiVersionType)(data.pixiVersion);
        this.setState({ data: data, versionType: versionType });
    };
    EditorSettingsDialog.prototype.render = function (props, state) {
        return ((0, preact_1.h)("div", { id: "settings-dialog", className: "modal", style: props.visible ? "display: flex" : "display: none" },
            (0, preact_1.h)("div", { className: "modal-content" },
                (0, preact_1.h)("header", null,
                    (0, preact_1.h)("h2", { className: "title" }, "Playground Settings"),
                    (0, preact_1.h)("div", { className: "btn-group" },
                        (0, preact_1.h)("button", { id: "settings-save", className: "btn", onClick: this._onSaveClick },
                            (0, preact_1.h)("span", { className: "fa fa-save", "aria-hidden": "true" }),
                            (0, preact_1.h)("span", { className: "label" }, "Save"))),
                    (0, preact_1.h)("span", { className: "fa fa-times close-btn", onClick: this._onCloseClick })),
                (0, preact_1.h)("div", { className: "form-wrapper" },
                    (0, preact_1.h)("form", null,
                        (0, preact_1.h)("fieldset", null,
                            (0, preact_1.h)("h4", null,
                                (0, preact_1.h)("label", null, "PixiJS Version")),
                            (0, preact_1.h)(Radio_1.RadioGroup, { name: "settings-version", selectedValue: state.versionType, onChange: this._onVersionChange },
                                (0, preact_1.h)(Radio_1.Radio, { value: pixiVersionType_1.PixiVersionType.Release, id: "settings-version-release" }),
                                (0, preact_1.h)("label", { for: "settings-version-release" }, "Latest Release"),
                                (0, preact_1.h)(Radio_1.Radio, { value: pixiVersionType_1.PixiVersionType.Tag, id: "settings-version-tag" }),
                                (0, preact_1.h)("label", { for: "settings-version-tag" }, "Specific Version"),
                                (0, preact_1.h)(Radio_1.Radio, { value: pixiVersionType_1.PixiVersionType.Custom, id: "settings-version-custom" }),
                                (0, preact_1.h)("label", { for: "settings-version-custom" }, "Custom Url")),
                            (0, preact_1.h)("br", null),
                            state.versionType === pixiVersionType_1.PixiVersionType.Tag ? this._renderTagVersionSelector(state)
                                : state.versionType === pixiVersionType_1.PixiVersionType.Custom ? this._renderCustomVersionSelector(state)
                                    : ''),
                        (0, preact_1.h)("fieldset", null,
                            (0, preact_1.h)("h4", null,
                                (0, preact_1.h)("label", { for: "settings-name" }, "Name")),
                            (0, preact_1.h)("input", { type: "text", className: "fullwidth", id: "settings-name", name: "name", placeholder: "e.g. My Cool Playground", value: state.data.name, onChange: (0, linkstate_1.default)(this, 'data.name') })),
                        (0, preact_1.h)("fieldset", null,
                            (0, preact_1.h)("h4", null,
                                (0, preact_1.h)("label", { for: "settings-desc" }, "Description")),
                            (0, preact_1.h)("input", { type: "text", className: "fullwidth", id: "settings-desc", name: "desc", placeholder: "e.g. A demo of how cool PixiJS is!", value: state.data.description, onChange: (0, linkstate_1.default)(this, 'data.description') })),
                        (0, preact_1.h)("fieldset", null,
                            (0, preact_1.h)("h4", null,
                                (0, preact_1.h)("label", { for: "settings-author" }, "Author")),
                            (0, preact_1.h)("input", { type: "text", className: "fullwidth", id: "settings-author", name: "author", placeholder: "e.g. Chester McDoodle", value: state.data.author, onChange: (0, linkstate_1.default)(this, 'data.author') })),
                        (0, preact_1.h)("fieldset", null,
                            (0, preact_1.h)("h4", null,
                                (0, preact_1.h)("label", null, "External Scripts")),
                            (state.data.externaljs || []).map(this._renderExternaljs),
                            (0, preact_1.h)("button", { id: "settings-add-externaljs", className: "btn", onClick: this._onAddExternaljs },
                                (0, preact_1.h)("span", { className: "fa fa-plus", "aria-hidden": "true" }),
                                (0, preact_1.h)("span", { className: "label" }, "Add Script"))),
                        (0, preact_1.h)("fieldset", { class: "stg-rows-group" },
                            (0, preact_1.h)("h4", null,
                                (0, preact_1.h)("label", null, "Attributes")),
                            (0, preact_1.h)("div", { class: "stg-row" },
                                (0, preact_1.h)("input", { type: "checkbox", name: "public", id: "settings-attr-public", checked: state.data.isPublic, onClick: this._onToggle }),
                                (0, preact_1.h)("label", { for: "settings-attr-public", title: "When checked this playground is publicly accessible." }, "Public")),
                            (0, preact_1.h)("div", { class: "stg-row" },
                                (0, preact_1.h)("input", { type: "checkbox", name: "autoUpdate", id: "settings-attr-autoUpdate", checked: state.data.autoUpdate, onClick: this._onToggle }),
                                (0, preact_1.h)("label", { for: "settings-attr-autoUpdate", title: "When checked the preview pane will automatically update when you make code changes." }, "Auto Update"))))))));
    };
    EditorSettingsDialog.prototype._renderTagVersionSelector = function (state) {
        return ((0, preact_1.h)("select", { id: "settings-version-tag-input", className: "fullwidth", value: state.data.pixiVersion, onChange: (0, linkstate_1.default)(this, 'data.pixiVersion') }, state.versionOptions.map(this._renderTagOption)));
    };
    EditorSettingsDialog.prototype._renderCustomVersionSelector = function (state) {
        return ((0, preact_1.h)("input", { type: "text", id: "settings-version-custom-input", className: "fullwidth", placeholder: "e.g. https://mydomain.com/pixi.js", value: state.data.pixiVersion, onChange: (0, linkstate_1.default)(this, 'data.pixiVersion') }));
    };
    EditorSettingsDialog.prototype._renderTagOption = function (tag, index) {
        return ((0, preact_1.h)("option", { key: "tag-option-".concat(index), value: tag }, tag));
    };
    EditorSettingsDialog.prototype._renderExternaljs = function (externaljs, index) {
        return ((0, preact_1.h)("div", { className: "externaljs-row" },
            (0, preact_1.h)("input", { key: "externaljs-input-".concat(index), "data-index": index, type: "text", placeholder: "https://mydomain.com/file.js", value: externaljs.url, onChange: this._onExternaljsChanged }),
            (0, preact_1.h)("span", { className: "fa fa-times", title: "Remove Script", "data-index": index, onClick: this._onRemoveExternaljs })));
    };
    EditorSettingsDialog.prototype._onAddExternaljs = function (e) {
        e.preventDefault();
        if (!this.state.data.externaljs)
            this.state.data.externaljs = [];
        this.state.data.externaljs.push({ url: '' });
        this.setState({ data: this.state.data });
    };
    EditorSettingsDialog.prototype._onRemoveExternaljs = function (e) {
        var target = e.target;
        var index = parseInt(target.dataset.index, 10);
        this.state.data.externaljs.splice(index, 1);
        this.setState({ data: this.state.data });
    };
    EditorSettingsDialog.prototype._onExternaljsChanged = function (e) {
        var target = e.target;
        var index = parseInt(target.dataset.index, 10);
        this.state.data.externaljs[index].url = target.value;
    };
    EditorSettingsDialog.prototype._onVersionChange = function (versionTypeStr) {
        var data = this.state.data;
        var versionType = parseInt(versionTypeStr, 10);
        if (!(versionType in pixiVersionType_1.PixiVersionType))
            return;
        switch (versionType) {
            case pixiVersionType_1.PixiVersionType.Release:
                data.pixiVersion = 'release';
                break;
            case pixiVersionType_1.PixiVersionType.Tag:
                data.pixiVersion = this.state.versionOptions[0] || 'release';
                break;
            case pixiVersionType_1.PixiVersionType.Custom:
                data.pixiVersion = '';
                break;
            default:
                (0, assertNever_1.assertNever)(versionType);
        }
        this.setState({ data: data, versionType: versionType });
    };
    EditorSettingsDialog.prototype._onToggle = function (evt) {
        var data = this.state.data;
        switch (evt.target.name) {
            case 'public':
                data.isPublic = !data.isPublic;
                this.setState({ data: data });
                break;
            case 'autoUpdate':
                data.autoUpdate = !data.autoUpdate;
                this.setState({ data: data });
                break;
        }
    };
    EditorSettingsDialog.prototype._onSaveClick = function (e) {
        e.preventDefault();
        if (this.props.onSaveClick)
            this.props.onSaveClick(this.state.data);
    };
    EditorSettingsDialog.prototype._onCloseClick = function () {
        if (this.props.onCloseClick)
            this.props.onCloseClick();
    };
    __decorate([
        decko_1.bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String, Number]),
        __metadata("design:returntype", void 0)
    ], EditorSettingsDialog.prototype, "_renderTagOption", null);
    __decorate([
        decko_1.bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Number]),
        __metadata("design:returntype", void 0)
    ], EditorSettingsDialog.prototype, "_renderExternaljs", null);
    __decorate([
        decko_1.bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Event]),
        __metadata("design:returntype", void 0)
    ], EditorSettingsDialog.prototype, "_onAddExternaljs", null);
    __decorate([
        decko_1.bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Event]),
        __metadata("design:returntype", void 0)
    ], EditorSettingsDialog.prototype, "_onRemoveExternaljs", null);
    __decorate([
        decko_1.bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Event]),
        __metadata("design:returntype", void 0)
    ], EditorSettingsDialog.prototype, "_onExternaljsChanged", null);
    __decorate([
        decko_1.bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], EditorSettingsDialog.prototype, "_onVersionChange", null);
    __decorate([
        decko_1.bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [MouseEvent]),
        __metadata("design:returntype", void 0)
    ], EditorSettingsDialog.prototype, "_onToggle", null);
    __decorate([
        decko_1.bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Event]),
        __metadata("design:returntype", void 0)
    ], EditorSettingsDialog.prototype, "_onSaveClick", null);
    __decorate([
        decko_1.bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], EditorSettingsDialog.prototype, "_onCloseClick", null);
    return EditorSettingsDialog;
}(preact_1.Component));
exports.EditorSettingsDialog = EditorSettingsDialog;
