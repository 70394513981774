"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Redirect = void 0;
var preact_1 = require("preact");
var preact_router_1 = require("preact-router");
var Redirect = (function (_super) {
    __extends(Redirect, _super);
    function Redirect() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Redirect.prototype.componentWillMount = function () {
        var _this = this;
        setTimeout(function () { return _this.redirect(); }, 100);
    };
    Redirect.prototype.redirect = function () {
        (0, preact_router_1.route)(this.props.to, true);
    };
    Redirect.prototype.render = function () {
        return null;
    };
    return Redirect;
}(preact_1.Component));
exports.Redirect = Redirect;
