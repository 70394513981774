"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPixiVersionType = exports.PixiVersionType = void 0;
var semver_1 = require("../util/semver");
var PixiVersionType;
(function (PixiVersionType) {
    PixiVersionType[PixiVersionType["Release"] = 0] = "Release";
    PixiVersionType[PixiVersionType["Tag"] = 1] = "Tag";
    PixiVersionType[PixiVersionType["Custom"] = 2] = "Custom";
})(PixiVersionType || (exports.PixiVersionType = PixiVersionType = {}));
function getPixiVersionType(version) {
    if (version === 'release')
        return PixiVersionType.Release;
    if (version.match(semver_1.rgxSemVer) !== null)
        return PixiVersionType.Tag;
    return PixiVersionType.Custom;
}
exports.getPixiVersionType = getPixiVersionType;
