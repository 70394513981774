"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Storage = void 0;
var Storage;
(function (Storage) {
    var localStorageExists = (function () { return typeof localStorage !== 'undefined'; })();
    function get(key) {
        if (!localStorageExists)
            return null;
        return localStorage.getItem(key);
    }
    Storage.get = get;
    function set(key, value) {
        if (!localStorageExists)
            return null;
        localStorage.setItem(key, value);
    }
    Storage.set = set;
    function remove(key) {
        if (!localStorageExists)
            return null;
        localStorage.removeItem(key);
    }
    Storage.remove = remove;
})(Storage || (exports.Storage = Storage = {}));
