"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Search = void 0;
var preact_1 = require("preact");
var queryUtils_1 = require("../util/queryUtils");
var service_1 = require("../service");
var TopBar_1 = require("../components/TopBar");
var Search = (function (_super) {
    __extends(Search, _super);
    function Search(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.state = {
            loading: true,
            results: null,
            error: null,
        };
        return _this;
    }
    Search.prototype.componentWillMount = function () {
        var _this = this;
        var searchStr = (0, queryUtils_1.getQueryParam)('q');
        if (searchStr) {
            (0, service_1.searchPlaygrounds)(searchStr, function (err, data) {
                if (err) {
                    _this.setState({ loading: false, results: null, error: err });
                }
                else {
                    _this.setState({ loading: false, results: data, error: null });
                }
            });
        }
        else {
            this.setState({ loading: false, error: new Error('No search query!') });
        }
    };
    Search.prototype.render = function () {
        var view;
        if (this.state.loading) {
            view = (0, preact_1.h)("span", null, "Loading...");
        }
        else if (this.state.error) {
            view = (0, preact_1.h)("pre", null,
                (0, preact_1.h)("code", null, this.state.error.stack));
        }
        else {
            view = (0, preact_1.h)("pre", null,
                (0, preact_1.h)("code", null, JSON.stringify(this.state.results, null, 4)));
        }
        return ((0, preact_1.h)("div", null,
            (0, preact_1.h)(TopBar_1.TopBar, { useHistoryReplace: true }),
            (0, preact_1.h)("br", null),
            view));
    };
    return Search;
}(preact_1.Component));
exports.Search = Search;
