"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonacoEditor = void 0;
var monaco = require("monaco-editor/esm/vs/editor/editor.api");
var preact_1 = require("preact");
var decko_1 = require("decko");
function noop() { }
var MonacoEditor = (function (_super) {
    __extends(MonacoEditor, _super);
    function MonacoEditor(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.editor = null;
        _this._currentValue = props.value;
        _this._preventTriggerChangeEvent = false;
        _this._containerElement = null;
        return _this;
    }
    MonacoEditor.prototype.componentDidMount = function () {
        this.initMonaco();
    };
    MonacoEditor.prototype.componentWillUnmount = function () {
        this.destroyMonaco();
    };
    MonacoEditor.prototype.componentDidUpdate = function (prevProps) {
        if (this.props.value !== this._currentValue) {
            this._currentValue = this.props.value;
            if (this.editor) {
                this._preventTriggerChangeEvent = true;
                this.editor.pushUndoStop();
                this.model.pushEditOperations([], [
                    {
                        range: this.model.getFullModelRange(),
                        text: this._currentValue,
                    },
                ], undefined);
                this.editor.popUndoStop();
                this._preventTriggerChangeEvent = false;
            }
        }
        if (prevProps.language !== this.props.language) {
            monaco.editor.setModelLanguage(this.editor.getModel(), this.props.language);
        }
        if (prevProps.theme !== this.props.theme) {
            monaco.editor.setTheme(this.props.theme);
        }
    };
    MonacoEditor.prototype.editorWillMount = function () {
        var editorWillMount = this.props.editorWillMount;
        editorWillMount();
    };
    MonacoEditor.prototype.editorDidMount = function (editor, model) {
        var _this = this;
        var _a = this.props, editorDidMount = _a.editorDidMount, onChange = _a.onChange;
        editorDidMount(editor, model);
        editor.onDidChangeModelContent(function (event) {
            var value = editor.getValue();
            _this._currentValue = value;
            if (!_this._preventTriggerChangeEvent) {
                onChange(value, event);
            }
        });
    };
    MonacoEditor.prototype.containerDidMount = function (containerElement) {
        this._containerElement = containerElement;
    };
    MonacoEditor.prototype.initMonaco = function () {
        if (!this._containerElement)
            return;
        var value = this.props.value !== null ? this.props.value : this.props.defaultValue;
        var _a = this.props, language = _a.language, theme = _a.theme, options = _a.options;
        this.editorWillMount();
        this.model = monaco.editor.createModel(value, language);
        this.editor = monaco.editor.create(this._containerElement, __assign({ model: this.model, theme: theme }, options));
        this.editorDidMount(this.editor, this.model);
    };
    MonacoEditor.prototype.destroyMonaco = function () {
        if (typeof this.editor !== 'undefined') {
            this.editor.dispose();
        }
    };
    MonacoEditor.prototype.render = function () {
        var _a = this.props, width = _a.width, height = _a.height;
        var fixedWidth = width.toString().indexOf('%') !== -1 ? width : "".concat(width, "px");
        var fixedHeight = height.toString().indexOf('%') !== -1 ? height : "".concat(height, "px");
        var style = {
            width: fixedWidth,
            height: fixedHeight,
        };
        return (0, preact_1.h)("div", { ref: this.containerDidMount, style: style, className: "react-monaco-editor-container" });
    };
    __decorate([
        decko_1.bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [HTMLDivElement]),
        __metadata("design:returntype", void 0)
    ], MonacoEditor.prototype, "containerDidMount", null);
    return MonacoEditor;
}(preact_1.Component));
exports.MonacoEditor = MonacoEditor;
MonacoEditor.defaultProps = {
    width: '100%',
    height: '100%',
    value: null,
    defaultValue: '',
    language: 'javascript',
    theme: 'vs',
    options: {},
    editorDidMount: noop,
    editorWillMount: noop,
    onChange: noop,
    requireConfig: {},
};
