"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.del = exports.put = exports.post = exports.get = void 0;
function sendRequest(method, url, data, callback) {
    var xhr = new XMLHttpRequest();
    xhr.open(method, url, true);
    xhr.addEventListener('error', function () {
        callback(new Error("Request failed. Status: ".concat(xhr.status, ", text: \"").concat(xhr.statusText, "\"")));
    }, false);
    xhr.addEventListener('load', function () {
        var res;
        try {
            res = JSON.parse(xhr.responseText);
        }
        catch (e) {
            res = xhr.responseText;
        }
        var err = null;
        if (Math.floor(xhr.status / 100) !== 2) {
            var msg = xhr.statusText;
            if (typeof res === 'string')
                msg = res;
            else if (typeof res.msg === 'string')
                msg = res.msg;
            err = new Error(msg);
        }
        callback(err, res);
    }, false);
    xhr.responseType = 'text';
    if (method !== 'GET' && data) {
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify(data));
    }
    else {
        xhr.send();
    }
    return xhr;
}
function get(url, callback) {
    return sendRequest('GET', url, null, callback);
}
exports.get = get;
function post(url, data, callback) {
    return sendRequest('POST', url, data, callback);
}
exports.post = post;
function put(url, data, callback) {
    return sendRequest('PUT', url, data, callback);
}
exports.put = put;
function del(url, callback) {
    return sendRequest('DELETE', url, null, callback);
}
exports.del = del;
